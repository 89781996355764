import React from "react";

//For when I can get sendgrid to work
//import Form from "react-bootstrap/Form";
//import Button from "react-bootstrap/Button";

import Hero from "../components/Hero/Hero";
import Content from "../components/Content";

class ContactPage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            name: '',
            email: '',
            message:'',
            disabled:false,
            emailSent:null
        }
    }

    //
    handleChange = (event) =>
    {
        const target = event.target;
        //If the type of the input is a textbox, it is checked. Otherwise it is whatever the value is.
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]:value
        })
    }

    //
    handleSubmit = (event) =>
    {
        //Stops the page from refreshing upon pressing the button
        event.preventDefault();

        this.setState({
            //Stops the user from submitting multiple emails
            disabled:true
        });
    }

    render()
    {
        return(
            <div>
                <Hero title={this.props.title}/>

                <Content>
                    <h2><u>My Email</u>:</h2><p> alx.mcneil@gmail.com</p>
                    <h2><u>Preferred Subject</u>:</h2> <p><i>Your company name</i>+Let's Talk</p>
                    <h2><u>My Phone</u>:</h2><p>(+1) 801-319-5109 (Text Only!)</p>
                </Content>

                {/* For when I can get sendGrid to work
                <Content>
                    {/*Bootstrap form*
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Label htmlFor="full-name">Full Name</Form.Label>
                            <Form.Control id="full-name" name="name" type="text" value={this.state.name} onChange={this.handleChange}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control id="email" name="email" type="email" value={this.state.email} onChange={this.handleChange}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label htmlFor="message">Message</Form.Label>
                            {/*Rows determines the height of a text area*
                            <Form.Control id="message" name="message" as="textarea" rows="3" value={this.state.message} onChange={this.handleChange}/>
                        </Form.Group>

                        <Button className="d-inline-block" variant="primary" type="submit" disabled={this.state.disabled}>
                            Send
                            
                        </Button>

                        {/*Way to prompt successful sending of message*
                        {this.state.emailSent === true && <p className="d-inline success-msg">Email Sent</p>}
                        {this.state.emailSent === false && <p className="d-inline error-msg">Error Sending Email</p>}

                    </Form>
                </Content>

                */}

            </div>
        );
    }
    
}

export default ContactPage;