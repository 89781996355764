import React from "react";

import CardInfo from "./CardInfo";

import '../index.css';

function Card(props)
{
    return(

            <div className="d-inline-block MyCard" onMouseEnter={(e)=>props.mouseEnter(props.item)}> 
                <img className="MyCard-image" src={props.item.imgSrc} alt={props.item.imgSrc}/>
                 {/*Conditionally rendered. Only show card if selected*/}
                 { props.item.selected && <CardInfo title = {props.item.title} subTitle = {props.item.subTitle} link={props.item.link} id={props.item.id}/>}
            </div>
    );
}

export default Card;