import React from "react";

//import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';

import Card from "../components/Card";



import Container from "react-bootstrap/esm/Container";

class Carousel extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state =
        {
            /*From HomePage.js*/
            items : props.items
        }
        
        this.handleCardMouseEnter = props.handleCardMouseEnter;
    }

    /*Makes the items. Maps cards to them*/
    makeItems = (items) =>
    {
        return items.map(item => {
            return <Card item={item} mouseEnter={(e => this.handleCardMouseEnter(item.id, e))} key={item.id}/>
        })
    }

    render()
    {
        return(
            <Container fluid={true}>
                <Row className="justify-content-around">
                    {this.makeItems(this.state.items)}

                </Row>
            </Container>
        );
    }
}

export default Carousel;