import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import "./ImageGallery.css";
 
const fadeImages = [
    require("../../assets/images/selfieOnTimp.jpg"),
    require("../../assets/images/viewFromTimp.jpg"),
    require("../../assets/images/flowers.jpg"),
    require("../../assets/images/viewFromNebo.jpg"),
  
];
 
function ImageGallery() {
  return (
    <div className="slide-container ">
      <Fade>
        <div className="each-fade ">
          <div className="image-container img-gallery-container">
            <img src={fadeImages[0]} className= "img-gallery-img"/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container img-gallery-container">
            <img src={fadeImages[1]} className= "img-gallery-img"/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container img-gallery-container">
            <img src={fadeImages[2]} className= "img-gallery-img"/>
          </div>
        </div>
        <div className="each-fade">
          <div className="image-container img-gallery-container">
            <img src={fadeImages[3]} className= "img-gallery-img"/>
          </div>
        </div>

      </Fade>
    </div>
  );
}

export default ImageGallery;
