import React from "react";

import Hero from "../components/Hero/Hero";
import Content from "../components/Content";

function AboutPage(props)
{
    return(
        //<p>About Works!</p>
        <div>
            <Hero title={props.title}/>

            <Content>
                <p>My name is <b>Alexander McNeil</b>. I am a software engineer with the majority of my experience in .NET development but also a fair amount in full stack development. I have a B.S in Computer Science (with an IS&T minor) from Utah Valley University, magna cum laude. </p>
                <p>I am actively looking for employment and would like to work at an innovative company where I can apply my skills to create or maintain great products.</p>
                <p>My skills include: <b>.NET framework, C#, C++, Javascript, React, Angular, AWS, and Microsoft SQL</b>.</p>
                <p>I grew up in and am currently living in the state of Utah. I consider myself a movie-buff and would like to one day have an online show dedicated to discussing films. My hobbies include video games, hiking, any sport related to the water, and books. </p>
                <p>I love learning and trying new things, regardless of the topic. It enriches my life and keeps the world adventurous.</p>
            
            </Content>
        </div>
    );
}

export default AboutPage;