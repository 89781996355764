import React from "react";
import {BrowserRouter as Router, Route, Link} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";

import './App.css';

class App extends React.Component {
    
  constructor(props)
  {
    super(props)
    {
      this.state =  {
        title: 'Alex McNeil',
        headerLinks:[
          {title: 'Home', path: '/'},
          {title: 'About', path: '/about'},
          {title: 'Contact', path: '/contact'},
          {title: 'Playground', path: '/playground'}  //To be explored after completion
        ],
      home:{
        title: 'Live Every Day',
        subTitle: 'Developer with a passion',
        paragraph: 'Learn about me and my projects below'
      },
      about:{
        title: 'About Me',
        
      },
      contact:{
        title: 'Let\'s Talk!',   
      }
      }
    }
  }
  render()
  {
    return (
      <Router>
        <Container className="p-0" fluid={true}>
          
            <Navbar className="border-bottom" bg="transparent" expand="lg">
              <Navbar.Brand>Alexander McNeil</Navbar.Brand>

              <Navbar.Toggle className="border-0" aria-controls="navbar-toggle"/>
              <Navbar.Collapse id="navbar-toggle">
                  <Nav className="ml-auto">
                    <Link className="nav-link" to="/">Home</Link>
                    <Link className="nav-link" to="/about">About</Link>
                    <Link className="nav-link" to="/contact">Contact</Link>
                  </Nav>
              </Navbar.Collapse>
            </Navbar>

            {/*Route essentially says "when path is x, render y"
                path must match the value in previous "Link" components
            */}
            <Route exact path="/">
              <HomePage title={this.state.home.title} subTitle={this.state.home.subTitle} paragraph={this.state.home.paragraph}/>
            </Route>
            <Route path="/about">
              <AboutPage title={this.state.about.title}/>
            </Route>
            <Route path="/contact">
              <ContactPage title={this.state.contact.title}/>
            </Route>

          <Footer/>

        </Container>
      </Router>
    );
  }
  
}

export default App;