import React from "react";

import Hero from "../components/Hero/Hero";
import Carousel from "../components/Carousel";

import Resume from "../components/Resume";
import ImageGallery from "../components/ImageGallery/ImageGallery";

import "../index.css";

import githublogo from '../assets/images/GitHub-logo-banner.png';
import linkedinlogo from '../assets/images/Linkedin-logo-banner.png';
import me from '../assets/images/Me-banner.png';


class HomePage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            title:props.title,
            subTitle:props.subTitle,
             paragraph:props.paragraph,

            carouselItems:[
                {
                    id:0,
                    title: 'My Resume',
                    subTitle: 'My Projects and Experience',
                    imgSrc: me,
                    link: '#resume-anchor',
                    selected:false
                },
                {
                    id:1,
                    title: 'My Github',
                    subTitle: 'New, but always improving',
                    imgSrc: githublogo,
                    link: 'https://github.com/VacuousGiant?tab=repositories',
                    selected:false
                },
                {
                    id:2,
                    title: 'My LinkedIn Page',
                    subTitle: 'Professional',
                    imgSrc: linkedinlogo,
                    link: 'https://linkedin.com/in/alexandermcneilprofessional',
                    selected:false
                }
            ]
        }
    }

    handleCardMouseEnter = (id, card) =>
    {
        let items = [...this.state.carouselItems];

        /*If the carousel item is selected and hovered over again, set to false and vice versa*/
        items[id].selected = items[id].selected ? false : true;

        /*Make each other item set to false for selected*/
        items.forEach(item=>{
            if(item.id !== id)
            {
                item.selected = false;
            }
        });

        this.setState({
            items
        });
    }

    

    render()
    {
        return(
            <div>
            <Hero title={this.state.title} subTitle={this.state.subTitle} paragraph={this.state.paragraph}/>      
            <ImageGallery/>
            <Carousel items={this.state.carouselItems} handleCardMouseEnter={this.handleCardMouseEnter}/>
    
            {/*Conditionally rendered resume if the first card (resume card) is selected.
            Has anchor tag for CardInfo link to jump to on the page*/}
            
            <a id="resume-anchor"></a>
            {this.state.carouselItems[0].selected && <Resume/>}
            </div>
        );
    }    
}

export default HomePage;