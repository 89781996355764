import React from "react";

import { useSpring, animated} from 'react-spring';

import {HashLink as Link} from 'react-router-hash-link';

function CardInfo(props)
{

    /*Takes in an object to represent things like opacity*/
    const style = useSpring({opacity:1, from: {opacity: 0}});

    return(
        <div>
        <animated.div className="MyCard-info" style={style}>
            <p className="MyCard-title">{props.title}</p>
            <p className="MyCard-subTitle">{props.subTitle}</p>
            {/*Link to go external site (Github, LinkedIn)*/}
            {props.id !== 0 && <a href={props.link} target="_blank" rel="noopener norefferer">View</a>}
            {/*Link to stay on same page (Resume in HomePage)*/}
            {props.id === 0 && <a href={props.link}>View</a>}
        </animated.div>
        
        </div>

    );
    
}

export default CardInfo;