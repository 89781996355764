import React from "react";

import { useSpring, animated} from 'react-spring';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import res1 from '../assets/images/ResumeImg1.png';
import res2 from '../assets/images/ResumeImg2.png';

import '../index.css';
import Jumbotron from "react-bootstrap/esm/Jumbotron";

function Resume(props)
{
    /*Takes in an object to represent things like opacity*/
    const style = useSpring({opacity:1, from: {opacity: 0}});

    return(
        <Jumbotron className="jumbotron-fluid p-3 bg-light">
        <Container fluid={true} >
            <Row className="justify-content-center">
                <Col md={8}>
                    <animated.div style={style}>
                        <img className="resume-img" src={res1} alt={res1}/>
                        <img className="resume-img" src={res2} alt={res2}/>
                    </animated.div>
                </Col>
        </Row>  
        </Container>
        </Jumbotron>

        
    );   
}

export default Resume;