import React from "react";
import ReactDOM from "react-dom";

import './index.css';

//import * as serviceWorker from './serviceWorker';
import App from "./App";

import 'bootstrap/dist/css/bootstrap.css';

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);